
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/store.enums";
import SignInForm from "@/view/pages/general/auth-forms/SignInForm.vue";
import SignUpForm from "@/view/pages/general/auth-forms/SignUpForm.vue";
import ForgotPasswordForm from "@/view/pages/general/auth-forms/ForgotPasswordForm.vue";
import { Auth } from "aws-amplify";

export default defineComponent({
  name: "login",
  components: {
    SignInForm,
    SignUpForm,
    ForgotPasswordForm,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-white");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-white");
    });

    var user = ref();

    Auth.currentAuthenticatedUser().then((authenticatedUser) => {
      user.value = authenticatedUser;
    });

    let title = "The best ideas don't wait";
    let subtitle = "We're your partner for innovation and growth.";
    let description =
      "Spend less time planning, more time building your business. <br/> Build amazing things together!";

    return {
      title,
      subtitle,
      description,
      user,
    };
  },
});
