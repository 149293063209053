
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { defineComponent, ref } from "vue";
// import { showForm } from "@/core/helpers/login-forms";
export default defineComponent({
  name: "sign-in-form",
  components: {
    Authenticator,
  },
  setup() {
    let changeAccountText = "Trocar conta";
    let welcomeText = "Hey";
    return {
      welcomeText,
      changeAccountText,
      formFields: ref({
        signIn: {
          username: {
            labelHidden: false,
            label: "Telefone",
            placeholder: "Entre com o seu telefone",
            dialCode: "+55",
            order: 1,
            dialCodeList: ["+55"],
          },
          password: {
            labelHidden: false,
            label: "Senha",
            placeholder: "Confirme sua senha",
            isRequired: false,
            order: 2,
          },
        },
        signUp: {
          name: {
            labelHidden: false,
            label: "Nome",
            placeholder: "Como você se chama?",
            isRequired: true,
            order: 1,
          },
          email: {
            labelHidden: false,
            label: "E-mail",
            placeholder: "Informe seu e-mail",
            isRequired: true,
            order: 2,
          },
          phone_number: {
            labelHidden: false,
            label: "Telefone",
            placeholder: "Informe seu número de telefone",
            dialCode: "+55",
            isRequired: true,
            order: 3,
            dialCodeList: ["+55"],
          },
          password: {
            labelHidden: false,
            label: "Senha",
            placeholder: "Entre com sua senha",
            isRequired: true,
            order: 4,
          },
          confirm_password: {
            labelHidden: false,
            label: "Confirme sua Senha",
            placeholder: "Confirme sua senha",
            order: 5,
          },
        },
        forceNewPassword: {
          password: {
            labelHidden: false,
            placeholder: "Entre com sua senha",
          },
        },
        resetPassword: {
          username: {
            labelHidden: false,
            placeholder: "Informe seu email",
          },
        },
        confirmResetPassword: {
          confirmation_code: {
            labelHidden: false,
            placeholder: "Digite o código de confirmação",
            label: "Código de Confirmação",
            isRequired: false,
          },
          confirm_password: {
            labelHidden: false,
            placeholder: "Confirme sua senha",
          },
        },
        setupTOTP: {
          QR: {
            totpIssuer: "test issuer",
            totpUsername: "amplify_qr_test_user",
          },
          confirmation_code: {
            labelHidden: false,
            label: "Código de Confirmação",
            placeholder: "Código de Confirmação",
            isRequired: false,
          },
        },
        confirmSignIn: {
          confirmation_code: {
            labelHidden: false,
            label: "Código de Confirmação",
            placeholder: "Código de Confirmação",
            isRequired: false,
          },
        },
      }),
    };
  },
});
